.aside {
  color: #5d6063;
  transition: 0.3s;
  display: flex;
  height: 100%;

  * {
    &::selection {
      background: transparent;
      /* WebKit/Blink Browsers */
    }

    &::-moz-selection {
      background: transparent;
      /* Gecko Browsers */
    }
  }

  & .menu {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    gap: 40px;
  }
}

.companyManagement {
  .menu {
    //align-items: center;
    padding-top: 12px;
  }
}

@media (max-width: 1140px) {
  .companyManagement {
    position: fixed;
    top: 4rem;
    left: -100vw;
    height: calc(100% - 4rem);
    width: 100vw;
    z-index: 1;
    background-color: #fff;
    overflow: auto;

    .menu {
      margin-top: 2.875rem;
      margin-bottom: 6.5rem;
      padding-left: 1.55rem;
      position: static !important;
      top: -3.75rem !important;
      display: block;
      height: auto;

      &.authorized {
        margin-top: 3.7rem;
      }
    }

    .darkTheme {
      color: #dadada;
    }
  }

  .openedSidebar {
    left: 0;
  }

  .darkTheme {
    background-color: #151a26;
  }
}

@media (max-width: 960px) {
  .aside {
    position: fixed;
    top: 4rem;
    left: -100vw;
    height: calc(100% - 4rem);
    width: 100vw;
    z-index: 1;
    background-color: #fff;
    overflow: auto;
  }

  .aside .menu {
    margin-top: 2.875rem;
    margin-bottom: 6.5rem;
    padding-left: 1.55rem;
    position: static !important;
    top: -3.75rem !important;
    display: flex !important;
    flex-direction: column;
    gap: 0;

    &.authorized {
      margin-top: 3.7rem;
    }
  }
}

// Dark Theme
.aside.darkTheme {
  color: #dadada;
}

@media (max-width: 960px) {
  .aside.darkTheme {
    background-color: #151a26;
  }

  .aside.openedSidebar {
    left: 0;
  }

  .aside .menu {
    display: block;
    height: auto;
  }
}

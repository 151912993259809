.Disclaimer {
  padding: 18px;
  border: 1px solid #d0d0d0;
  border-right-width: 0px;
  border-left-width: 0px;
  //position: absolute;
  //top: 48px;
  //right: 0;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  transition: 0.2s;

  & > span {
    font-weight: 700;
    font-size: 14px;

    & a {
      color: #252626;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.darkTheme {
    background-color: #2c2c2c;
    border-color: #919191;

    & > span a {
      color: #dadada;
    }
  }
}

@media (max-width: 851px) {
  .Disclaimer {
    position: relative;
    top: 82px;
  }
}

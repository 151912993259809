.menuItem {
  position: relative;
  border-top: 2px solid transparent;
  display: flex;
  gap: 40px;
  box-sizing: border-box;
  align-items: baseline;

  svg,
  .icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
    margin-bottom: -1px;

    > path {
      fill: #232836;
    }
  }

  &:hover {
    cursor: unset;
    color: #395697;

    svg {
      path {
        fill: #395697 !important;
      }
    }

    .title {
      cursor: default !important;
      color: #395697;
    }

    & .dropDown {
      display: block !important;
    }

    & .closedDropDown {
      display: none !important;
    }
  }

  & > span,
  .managementTools > .title,
  & > a {
    color: #232836;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 24px;
    font-family: Sarabun-Medium, serif;

    &:hover {
      color: #395697;
    }
  }

  &.active {
    &:after {
      content: '';
      width: -webkit-fill-available;
      height: 2px;
      background: #232836;
      position: absolute;
      bottom: -5px;
    }
  }

  &.active {
    color: #232836;
    border-left-color: #5d6063 !important;
  }

  & > span {
    cursor: default;
  }

  & .managementTools {
    height: 34px;

    .title {
      cursor: pointer;
      position: relative;
    }

    .ruTitle {
      font-family: 'Lato', Arial, serif;
    }

    & .dropDown {
      display: none;
      word-break: break-all;
      border: 1px solid #cbcbcb;
      font-size: 14px;
      position: absolute;
      margin-top: 8.5px;
      left: 0;
      top: 25px;
      z-index: 5;
      background-color: #fff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
      height: auto;
      max-width: 230px;
      width: max-content;
    }

    .content {
      position: relative;
    }

    .content:first-child {
      .subTitle {
        &:hover {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }

    // I have no idea how they managed to add it here
    // but this has to be removed
    .subTitle:last-child {
      display: none !important;
    }

    .dropDown:last-child {
      > span {
        &:hover {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    .content > .subTitle,
    .dropDown > .subTitle {
      display: block;
      cursor: pointer;
      position: relative;
      padding: 10px 20px;
      color: #000;
      font-family: Sarabun-Medium, serif;

      &:hover,
      > span,
      > a {
        color: #395697;
        position: relative;
      }
    }

    .letterSpacing {
      letter-spacing: 1px;
      font-family: 'Lato', Arial, serif !important;
    }

    .subMenu {
      list-style: none;
      font-size: 14px;
      transition: 0.3s;
      display: none;
      font-weight: 400;
      background-color: #fff;
      border: 1px solid #cbcbcb;
      box-sizing: border-box;
      position: absolute;
      left: 100%;
      top: 0;
      z-index: 2;
      min-width: 280px;
      padding: 10px 0;
      margin: 0;

      & .subMenuTitle {
        padding: 2px 16px;
        font-weight: 700;
        font-size: 12px;
        color: #c4c4c4;
        margin-top: 10px;

        &:first-of-type {
          margin-top: 5px;
        }
      }
    }

    .content:hover {
      .subMenu {
        display: block !important;
      }
    }
  }

  &:not(.active):hover > span,
  .title:hover,
  &:not(.active) > a:hover {
    font-weight: 400;
  }

  &.darkTheme {
    .subMenu,
    .dropDown {
      background-color: #232836;

      & .subMenuTitle,
      &.subTitle {
        color: #717272;
      }
    }

    &.active {
      &:after {
        background: #fff;
      }
    }

    &:not(.active):hover > span,
    &:not(.active) > a:hover,
    & > span,
    .content > .subTitle,
    .dropDown > .subTitle,
    .managementTools > .title,
    & > a {
      color: #dadada !important;

      &:hover {
        color: #8699c2 !important;
      }
    }

    .dropDown {
      &:before {
        color: #717272;
      }
    }

    &.active > span {
      border-left-color: #ddd !important;
      border-bottom-color: #000 !important;
    }

    > span,
    > a {
      &:hover {
        color: #8699c2 !important;
      }
    }

    svg {
      > path {
        fill: #d9d9d9;
      }
    }

    &:hover {
      svg {
        > path {
          fill: #8699c2 !important;
        }
      }
    }
  }
}

.companyManagementPage {
  & > span,
  .managementTools > .title,
  & > a {
    font-size: 16px;
    display: flex;
  }

  &.active {
    color: #395697;

    a,
    .title {
      svg {
        path {
          fill: #395697 !important;
        }
      }
    }

    > a {
      color: #395697;
    }

    &:after {
      content: '';
      display: none;
    }
  }
}

.companyManagementPage {
  &.darkTheme {
    &.active {
      a,
      .title {
        color: #8699c2 !important;

        svg {
          path {
            fill: #8699c2 !important;
          }
        }
      }
    }
  }
}

.companyManagementPageEng {
  > a {
    align-items: flex-end;
  }
}

.companyManagementPageRu {
  & > a {
    > svg {
      margin-top: -2px;
    }
  }
}

.ruVersion {
  > a,
  span,
  &.content {
    font-family: 'Lato', Arial, serif;
  }

  .managementTools {
    .content {
      .subTitle {
        font-family: 'Lato', Arial, serif;
      }
    }
  }
}

@media (max-width: 1366px) {
  .menuItem {
    & > span,
    .managementTools > .title,
    & > a {
      font-size: 20px;
    }

    svg,
    .icon {
      margin-bottom: -2px;
    }
  }

  .companyManagementPageEng {
    a {
      svg,
      .icon {
        margin-bottom: 3px;
      }
    }
  }

  .companyManagementPageRu {
    a {
      svg,
      .icon {
        margin-top: 0;
      }
    }
  }
}

@media (min-width: 851px) and (max-width: 1255px) {
  .menuItem {
    margin: 0 4px;
    line-height: 25px;

    &:last-child {
      align-items: flex-start;
    }

    & .subMenu {
      line-height: normal;
    }
  }
}

@media (max-width: 1140px) {
  .companyManagementPage {
    font-size: 12px;
    margin: 0px;
    display: inherit;
    padding: 6px 0rem;
    border-bottom-width: 0px;
    height: auto;

    .content {
      padding-bottom: 20px;
    }

    &::after {
      display: none;
    }

    & > span,
    & > a,
    .dropDown > span,
    .content > span {
      border-left: 0.0625rem solid transparent;
      padding: 0.25rem 0 0.25rem 1rem !important;
      font-weight: 700;
      font-size: 20px;
      width: max-content;
    }

    &.active > p,
    &.active > span,
    &.active:hover > span,
    &.active > a {
      font-weight: 700;
      color: #252626 !important;
    }

    &:not(.active):hover > span,
    &:not(.active) > a:hover {
      font-weight: 700;
      color: #252626 !important;
    }

    &.darkTheme {
      &.active > p,
      &.active > span,
      &.active:hover > span,
      &.active > a,
      &:not(.active):hover > span,
      .dropDown > .subTitle,
      & > p:hover,
      & > a:hover {
        color: #dadada !important;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: transparent;
    }

    .content:first-child:hover {
      .subMenu {
        top: 0;
      }
    }

    .content:nth-child(2):hover {
      .subMenu {
        top: 0;
      }
    }

    .managementTools {
      height: unset;

      .title {
        display: none;
      }

      .dropDown {
        display: block;
        position: relative;
        border: none;
        background-color: transparent;
        box-shadow: unset;
        padding: 0;
        margin: 0;

        &:before {
          display: none;
        }

        > span {
          padding-left: unset;
        }

        & .subTitle {
          font-size: 20px;

          &:nth-child(3) {
            padding-bottom: 15px !important;
          }

          &:hover {
            background-color: unset;
          }
        }
      }

      .subMenu {
        padding-left: 1rem;
        padding-top: 0;
        display: block;
        position: relative;
        left: 0;
        border: none;
        font-weight: 300;
        background-color: transparent !important;

        &Title {
          display: none;
        }
      }
    }

    &.darkTheme {
      &:nth-child(3) {
        > a {
          svg {
            path {
              fill: #d9d9d9 !important;
            }
          }
        }
      }
    }

    // This is padding between Company Management and UXCore links
    &:nth-child(3) {
      padding-top: 18px !important;

      > a {
        display: flex !important;

        svg {
          path {
            fill: #232836 !important;
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .menuItem {
    font-size: 12px;
    margin: 0px;
    display: inherit;
    padding: 6px 0rem;
    border-bottom-width: 0px;
    height: auto;

    .content {
      padding-bottom: 20px;
    }

    &::after {
      display: none;
    }

    & > span,
    & > a,
    .dropDown > span,
    .content > span {
      border-left: 0.0625rem solid transparent;
      padding: 0.25rem 0 0.25rem 1rem !important;
      font-weight: 700;
      font-size: 20px;
      width: max-content;
    }

    &.active > p,
    &.active > span,
    &.active:hover > span,
    &.active > a {
      font-weight: 700;
      color: #252626 !important;
    }

    &:not(.active):hover > span,
    &:not(.active) > a:hover {
      font-weight: 700;
      color: #252626 !important;
    }

    &.darkTheme {
      &.active > p,
      &.active > span,
      &.active:hover > span,
      &.active > a,
      &:not(.active):hover > span,
      .dropDown > .subTitle,
      & > p:hover,
      & > a:hover {
        color: #dadada !important;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: transparent;
    }

    .content:first-child:hover {
      .subMenu {
        top: 0;
      }
    }

    .content:nth-child(2) {
      margin-top: -17px !important;
      padding-top: unset !important;
    }

    .content:nth-child(2):hover {
      .subMenu {
        top: 0;
      }
    }

    .managementTools {
      height: unset;

      .title {
        display: none;
      }

      .dropDown {
        display: block;
        position: relative;
        border: none;
        background-color: transparent;
        box-shadow: unset;
        padding: 0;
        margin: 0;

        &:before {
          display: none;
        }

        > span {
          padding-left: unset;
        }

        & .subTitle {
          font-size: 20px;

          &:nth-child(3) {
            padding-bottom: 15px !important;
          }

          &:hover {
            background-color: unset;
          }
        }
      }

      .subMenu {
        padding-left: 2rem;
        padding-top: 0;
        display: block;
        position: relative;
        left: 0;
        border: none;
        font-weight: 300;
        background-color: transparent !important;

        &Title {
          display: none;
        }
      }
    }

    &.darkTheme {
      .active {
        > a {
          border-left-color: #fff !important;
        }
      }
    }

    // This is padding between Company Management and UXCore links
    &:nth-child(3) {
      padding-top: unset !important;
      order: 3 !important;
    }

    &:last-child {
      order: 2 !important;
    }

    &:nth-child(2) {
      order: 4 !important;
      margin-top: -17px !important;
      padding-top: unset !important;
    }
  }

  .menuItem:nth-of-type(n + 3) {
    > a {
      display: block;
    }
  }

  .menuItem:last-child {
    svg,
    .icon {
      margin-bottom: -2px;
    }
  }

  .active {
    > a {
      border-left-color: #252626 !important;
    }

    &.darkTheme {
      > a {
        border-left-color: #fff !important;
      }
    }
  }
}

.subMenuItem {
  display: flex;
  border-left: 0.0625rem solid transparent;

  & > span,
  & > a {
    cursor: pointer;
    position: relative;
    color: #252626 !important;
    text-decoration: none !important;
    transition: 0s;
  }

  & > a {
    width: 100%;
    white-space: nowrap;
    padding: 0 16px;
    height: 32px;
    align-items: center;
    display: flex;
    align-items: center;
    font-family: Sarabun-Medium, serif;

    &:hover,
    &.active {
      color: #395697;
    }

    & > span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      font-family: Sarabun-Medium, serif;

      &:hover,
      &.active {
        color: #395697;
      }
    }
  }

  & > span {
    opacity: 0.5;
    cursor: default;

    &:hover,
    &.active {
      color: #395697;
    }
  }

  &.active,
  &:hover {
    span,
    a {
      color: #395697;
    }
  }

  /* Contains Answers Section */
  & .containsAnswers {
    white-space: initial;
    background-color: #fff;
    border: 0.0625rem solid #a2a2a2;
    color: #5d6063;
    position: absolute;
    left: calc(100% + 1.25rem);
    top: -1.25rem;
    visibility: hidden;
    z-index: 2;
    width: 18.75rem;
    padding: 0.875rem;
    border-radius: 0.25rem;
    font-weight: 700;
    opacity: 0;
    word-break: break-word;

    & ul {
      padding-left: 0.9375rem;
    }

    & li {
      list-style-type: disc;
    }

    &:before {
      content: '';
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      top: 30px;
      left: -0.4375rem;
      border: 0.0625rem solid gray;
      border-right-width: 0;
      border-bottom-width: 0;
      transform: rotate(-45deg);
      background-color: #fff;
    }
  }

  & a:hover .containsAnswers {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }

  & .Icon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 16px;
    top: 10px;
  }

  &.darkTheme {
    & > a {
      color: #dadada !important;
    }

    & .containsAnswers {
      background-color: #000;
      border: 0.0625rem solid #a2a2a2;
      color: #dadada;
    }

    & .containsAnswers:before {
      border: 0.0625rem solid #a2a2a2;
      border-right-width: 0;
      border-bottom-width: 0;
      background-color: #000;
    }
  }
}

.ruVersion {
  & > span {
    font-family: 'Lato', Arial, serif;
  }

  & > a {
    font-family: 'Lato', Arial, serif;
    & > span {
      font-family: 'Lato', Arial, serif;
    }
  }
}
@media(max-width: 1140px) {
  .companyManagementPage {
    font-size: 18px;
    margin-top: 0.625rem;

    & .Icon {
      width: 17px;
      height: 25px;
      position: absolute;
      left: -14px;
      top: 0.0625rem;
    }

    &.active {
      border-color: #5d6063;
      background-color: transparent;
    }

    & .Icon {
      top: 0;
    }

    &.active > a {
      font-weight: 700;

      & span {
        text-decoration: none;
      }
    }

    & > span,
    & > a {
      padding-left: 0.625rem;
    }

    & a {
      text-overflow: ellipsis;
      width: calc(100% - 1.875rem);
      display: inline-block;

      & .containsAnswers {
        display: none;
      }
      & > span {
        max-width: 125%;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      background-color: transparent;
    }

    &.darkTheme {
      span {
        &:hover {
          color: #8699c2;
        }
      }

      &.active {
        border-color: #dadada !important;

        span {
          color: #8699c2;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 960px) {
  .contains-answers {
    display: none !important;
  }

  .subMenuItem {
    font-size: 18px;
    margin-top: 0.625rem;

    & .Icon {
      width: 17px;
      height: 25px;
      position: absolute;
      left: -14px;
      top: 0.0625rem;
    }

    &.active {
      border-color: #5d6063;
      background-color: transparent;
    }

    & .Icon {
      top: 0;
    }

    &.active > a {
      font-weight: 700;

      & span {
        text-decoration: none;
      }
    }

    & > span,
    & > a {
      padding-left: 0.625rem;
    }

    & a {
      text-overflow: ellipsis;
      width: calc(100% - 1.875rem);
      display: inline-block;

      & .containsAnswers {
        display: none;
      }
      & > span {
        max-width: 125%;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      background-color: transparent;
    }

    &.darkTheme {
      span {
        &:hover {
          color: #8699c2;
        }
      }

      &.active {
        border-color: #dadada !important;

        span {
          color: #8699c2;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}
